@import '/src/assets/scss/global/index';

.section--game-selection {
	height: 100%;
	position: relative;
}

.section--game-selection .section__inner {
	background-image: url('/assets/img/home_bg_mobile.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	margin: 0 auto;
	max-width: 1800px;
	position: relative;

	@include respond-above(medium) {
		background-image: url('/assets/img/home_bg_desktop.png');
	}
}

.markers {
	height: 100%;
	position: relative;
	width: 100%;
}

// .marker--0 {
// 	left: 52.5%;
// 	top: -2%;
// }

// .marker--1 {
// 	left: 64.7%;
// 	top: 35%;
// }

// .marker--2 {
// 	left: 68%;
// 	top: 17.5%;
// }

// .marker--3 {
// 	left: 34%;
// 	top: 74%;
// }

// .marker--4 {
// 	left: 24.2%;
// 	top: 50%;
// }


