@import '/src/assets/scss/global/media-queries';

.modal--game {
	@include respond-above(large) {
		display: none;
	}
}

.modal--game .ReactModal__Overlay {
	z-index: 10;
}
