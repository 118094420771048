@import "../../assets/scss/global/media-queries";

$card-tile-height: 319px;
$card-tile-width: 264px;

.card {
	position: relative;
}

.card--tile {
	justify-self: center;
	transform-style: preserve-3d;
	width: 100%;

	@include respond-above(medium) {
		max-height: $card-tile-height;
		max-width: $card-tile-width;
		padding-top: calc(($card-tile-height / $card-tile-width) * 100%);
	}

	@media only screen and (max-height: 900px) and (min-width: 768px) {
		padding-top: 90%;
	}
}

.card--tile .card-back,
.card--tile .card-front {
	border: 11px solid white;
	border-radius: 6px;
	box-shadow: 0px 4px 4px 0px #00000033;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.card--tile .card-back {
	background: url("/assets/img/logo-mark.svg"), #CF102D;
	background-position: center center;
	background-repeat: no-repeat;
	margin: 0;
	padding: 0;
	transition: background 50ms ease, transform 200ms ease-in 200ms;
	transform: translateZ(0px);
}

.card--tile:hover .card-back {
	background: url("/assets/img/logo-mark.svg"), var(--bright-red);
	background-position: center center;
	background-repeat: no-repeat;
}

.card--tile .card-back__image {
	height: 78.25px;
	width: 76px;
}

.card--tile .card-front {
	background-color: #000;
	left: 0;
	position: absolute;
	top: 0;
	transform: rotateY(90deg);
	transition: all ease-in 200ms;
}

.card--tile .card-front .card-front__image {
	height: 100%;
	object-fit: cover;
	object-position: center center;
	transition: opacity 250ms ease 500ms;
	width: 100%;
}

.is-flipped .card-front {
	transform: rotateY(0deg) translateZ(0px);
	transition-delay: 200ms;
}

.is-flipped .card-back {
	transform: rotateY(90deg) translateZ(-0.1px);
	transition-delay: 0ms;
}

.is-matched .card-front img {
	opacity: 0.5;
}




