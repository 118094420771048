@function valid-weight($available-weights, $weight) {

	@if index($available-weights, $weight) {

		@return $weight;
	}

	@else {

		@error "Sorry, but #{$weight} is not an available weight. The available weights are: #{$available-weights}.";
	}
}

@mixin sans($weight: 400, $style: normal, $print: false) {
	$available-weights: 400, 700, 900; /* stylelint-disable-line */

	@if $print == true {
		font-family: 'Arial', sans-serif;
	}
	font-style: $style;
	// prettier-ignore
	font-weight: valid-weight($available-weights, $weight); /* stylelint-disable-line */
}

@mixin serif($weight: 300, $style: normal ) {
	$available-weights: 400, 500, 700, 800, 900; /* stylelint-disable-line */

	font-family: 'Georgia', serif;
	font-style: $style;
	// prettier-ignore
	font-weight: valid-weight($available-weights, $weight); /* stylelint-disable-line */
}
