@import '/src/assets/scss/global/fonts';
@import '/src/assets/scss/global/media-queries';

.social-share {
	margin-top: 30px;
	@include respond-above(large) {
		float: left;
		width: 50%;
		margin-top: 20px;
		margin-bottom: 30px;
	}
}

.social-share__label {
	@include sans(400);
	font-size: 14px;
	letter-spacing: 2px;
	line-height: 1.1;
	text-transform: uppercase;
	padding-right: 20px;
}

.social-items {
	display: inline-flex;
	gap: 15px;
}

.social-item {}

.social-item__link {
	align-items: center;
	border: 2px solid var(--red);
	border-radius: 50%;
	display: flex;
	height: 30px;
	justify-content: center;
	transition: all 150ms ease;
	width: 30px;
}

.social-item__link:hover {
	border-color: var(--bright-red);
}

.social-item--facebook svg {
	height: 16px;
	width: 7.5px;

}

.social-item--twitter svg {
	height: 13px;
	width: 16px;
}

.social-item--email svg {
	height: 12px;
	width: 14px;
}

.social-item svg path {
	fill: var(--red);
	transition: all 150ms ease;
}

.social-item svg path {
	fill: var(--bright-red);
}
