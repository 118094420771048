@import "./assets/scss/global/index";

.memory-game {
	align-items: center;
	background-color: #4daca9;
	display: flex;
	height: 100%;
	padding-bottom: 20px;
	padding-top: 20px;
	position: relative;

	@include respond-above(medium) {
		padding-bottom: 0;
		padding-top: 0;
	}
}

.memory-game-bg {
	height: 100%;
	left: 0;
	object-fit: cover;
	position: absolute;
	top: 0;
	width: 100%;
}

.memory-game .container {
	@include container( $padding: 20px);
	align-items: center;
	display: flex;
	height: calc(100vh - var(--headerHeight) - 40px);
	width: 100%;
}

.card-grid {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr;
	height: 100%;
	width: 100%;

	@include respond-above(medium) {
		grid-gap: 30px;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		height: auto;
	}

	@include respond-above(large) {
		grid-gap: 50px;
	}
}
