@import '/src/assets/scss/global/media-queries';
@import '/src/assets/scss/global/fonts';

.textblock *:first-child {
	margin-top: 0;
}

.textblock *:last-child {
	margin-bottom: 0;
}

.textblock p {
	@include serif(400);
	font-size: 16px;
	line-height: 140%;

	@include respond-above(medium) {
		font-size: 18px;
	}
}
