@import '/src/assets/scss/global/media-queries';
@import '/src/assets/scss/global/fonts';

.app-header {
	align-items: center;
	background-color: #fff;
	box-shadow: 0px 4px 4px rgba(#000, 0.2);
	display: flex;
	flex: 0 0 auto;
	height: var(--headerHeight);
	justify-content: space-between;
	position: relative;
	width: 100%;
	z-index: 10;
}

#progress-bar {
	display: block;
	height: 100%;
	width: 0px;
	background-color: var(--red);
	background-image: linear-gradient(
		center bottom,
		var(--red) 37%,
		var(--red) 69%
	);
	position: relative;
	overflow: hidden;
	font-size: 15px;
	text-align: center;
	color: white;
	transition: all 2.0s ease;	
}

#logo-white {
	margin-left: -76px;
	transition: clip-path 1.0s;
	clip-path: polygon(0 0,0% 0, 0% 100%, 0% 100%);
	@include respond-above(large) {
		margin-left: -90px;
	}
}

.white-text {
	color: white !important;
}

.site-branding {
	line-height: 0;
	position: absolute;
	padding: 0 10px;
	@include respond-above(large) {
		padding: 0 34px;
	}
}

.site-branding a {
	display: block;
}

.site-branding a:focus-visible {
	border-radius: 5px;
	outline: 1px solid var(--red);
}

.site-branding img {
	max-height: 27px;

	@include respond-above(medium) {
		max-height: 32px;
	}
}

.instructions {
	@include sans(400);
	color: var(--red);
	font-size: 14px;
	font-weight: 900;
	letter-spacing: 2px;
	line-height: 1.2;
	opacity: 1;
	text-align: center;
	text-transform: uppercase;
	padding-right: 20px;

	@include respond-above(large) {
		font-size: 18px;
		left: 50%;
		letter-spacing: 3px;
		position: absolute;
		transform: translateX(-50%);
		padding-right: 0px;
	}
}

.drawer--is-open .instructions {
	opacity: 0;
}
