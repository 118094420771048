@import '/src/assets/scss/global/media-queries';
@import '/src/assets/scss/global/fonts';
@import '/src/assets/scss/global/typography';

.card--game {
	--side-padding: 30px;
	--top-padding: 40px;

	@include respond-above(medium) {
		--side-padding: 65px;
		--top-padding: 45px;
	}

	background: var(--light-blue);
	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
	overflow: auto;
	// padding: 45px 65px;
	position: relative;

	@include respond-above(medium) {
		width: 600px;
	}
}

.card--game .card-header {
	background-color: var(--white);
	padding-bottom: 70px;
	padding-left: var(--side-padding);
	padding-right: calc(var(--side-padding) + 20px);
	padding-top: var(--top-padding);

	@include respond-above(medium) {
		padding-bottom: 100px;
	}
}

.card--game .card-header__title {
	@include h1;
	margin: 0 0 10px 0;

	@include respond-above(medium) {
		margin: 0 0 14px 0;
	}
}

.card--game .card-header__location {
	@include sans(400);
	font-size: 14px;
	letter-spacing: 2px;
	line-height: 1.1;
	margin: 0;
	text-transform: uppercase;

	@include respond-above(medium) {
		font-size: 18px;
		letter-spacing: 3px;
	}
}

.card--game .card-body {
	margin-top: -42px;
	padding-bottom: 45px;
	padding-left: var(--side-padding);
	padding-right: var(--side-padding);

	@include respond-above(medium) {
		margin-top: -70px;
		padding-bottom: 50px;
	}
}

.card--game .card__image-container {
	line-height: 0;
	margin-bottom: 26px;
	min-height: 155px;

	@include respond-above(large) {
		min-height: 232px;
	}
}

.card--game .card__image {
	width: 100%;
}

.card--game .card__description {
	margin-bottom: 27px;
}

.modal .card--game {
	width: 100%;
}
