@import '/src/assets/scss/global/media-queries';

.ReactModal__Overlay {
	opacity: 0;
	transform: translateZ(1px);
	transition: opacity 250ms ease-in-out;
	z-index: 2000;
}

.ReactModal__Overlay--after-open{
	opacity: 1;
}

.ReactModal__Overlay--before-close{
	opacity: 0;
}

.ReactModal__Body--open {
	overflow: hidden;
}

.ReactModal__Content {
	@include respond-above(large) {
		margin-top: calc(var(--headerHeight) / 2);
	}
}

.btn-modal-close {
	background: none;
	border: 0;
	height: 23px;
	padding: 0;
	position: absolute;
	right: 20px;
	top: 20px;
	width: 23px;

	@include respond-above(medium) {
		height: 28px;
		right: 30px;
		top: 30px;
		width: 28px;
	}
}

.btn-modal-close path {
	transition: all 150ms ease;
}

.btn-modal-close:hover path {
	fill: var(--bright-red);
}
