* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*::before,
*::after {
	box-sizing: border-box;
}

html {
	text-rendering: optimizeLegibility;
}

body {
	@include sans(400, normal, true);
	height: 100vh;
}

.app {
	display: flex;
	flex-direction: column;
	height: 100%;
}

picture {
	display: block;
}

img {
	height: auto;
	max-width: 100%;
}

a {
	text-decoration: none;
}

button {
	cursor: pointer;
}

ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

.hide {
	display: none;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:active) {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}

.container {
	@include container();
}
