@import '/src/assets/scss/global/fonts';
@import '/src/assets/scss/global/media-queries';

.button {
	@include sans(700);
	background-color: var(--red);
	border: 0;
	color: var(--white);
	display: inline-block;
	font-size: 18px;
	letter-spacing: 0;
	line-height: 1;
	padding: 14px 25px;
	text-transform: uppercase;
	transition: all 150ms ease;

	@include respond-above(medium) {
		font-size: 24px;
		padding: 18px 30px;
	}
}

.button:hover {
	background-color: var(--bright-red);
}

.button[type=button],
.button[type=reset],
.button[type=submit],
button {
	-webkit-appearance: none;
}

[data-whatinput="keyboard"] .button:focus-visible {
	outline: 2px solid blue;
}

.button svg {
	height: 10px;
	margin-left: 10px;
	width: 5px;

	@include respond-above(medium) {
		height: 16px;
		margin-left: 15px;
		width: 8px;
	}
}

.button--small {
	font-size: 18px;
	padding: 14px 25px;
}

.button--small svg {
	height: 14px;
	margin-left: 10px;
	width: 7px;
}

.button--hollow {
	--color: var(--red);
	background: none;
	border: 3px solid var(--color);
	color: var(--color);
	padding: 8px 19px;

	@include respond-above(medium) {
		padding: 12px 24px;
	}
}

.button--hollow:hover {
	background: none;
	--color: var(--bright-red);
}

.button--hollow svg path {
	fill: var(--red);
}
