@import '/src/assets/scss/global/fonts';
@import '/src/assets/scss/global/media-queries';

.connect {
	@include respond-above(large) {
		float: left;
		width: 50%;
	}
}

.connect .links {
	margin-top: 30px;
}

.connect .links li + li {
	margin-top: 30px;
}

.connect .links a {
	@include sans(400);
	border-bottom: 1px solid #000;
	color: var(--black);
	display: inline-block;
	font-size: 14px;
	letter-spacing: 2px;
	line-height: 1.1;
	padding-bottom: 2px;
	text-transform: uppercase;
	transition: all 150ms ease;
}

.connect .links a:hover {	
	border-bottom: 1px solid #CF102D;
	color: #CF102D;
}

.connect .links svg {
	height: 10px;
	margin-left: 6px;
	width: 5px;
}

.connect .links svg path {
	fill: var(--black);
}

.connect .links a:hover > svg > path {
	fill: #CF102D;
}
