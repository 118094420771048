@import '/src/assets/scss/global/media-queries';

.marker {
	background: none;
	border: 0;
	filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.4));
	height: 46px;
	left: 50%;
	padding: 0;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 30px;

	@include respond-above(medium) {
		height: 64px;
		width: 41px;
	}
}

.marker .map-pin-inside {
	fill: var(--red);
	transition:  all 150ms ease;
}

.marker .map-pin-outline {
	fill: transparent;
}

.marker .map-pin-ring {
	fill: var(--white);
}

.marker:hover .map-pin-inside {
	fill: var(--bright-red);
}

.marker.is-active .map-pin-outline {
	fill: var(--white);
}

.marker:focus-visible .map-pin-outline {
	fill: var(--white);
}

.marker.is-complete .map-pin-inside {
	fill: var(--grey);
}

.react-tiny-popover-container .card--game {
	max-height: 80vh;
	overflow-y: scroll;
}

.drawer--is-open .marker {
	transform: translateY(-1000px);
}

$markerSize: 35px;

// .pulse {
//     position: absolute;
//     top: 30%;
//     left: 50%;
//     display: block;
//     width: $markerSize;
//     height: $markerSize/3.5;
//     background: var(--red);
//     /* background alternative white */
//     border-radius: 50%;
//     transform: translate(-50%, 400%);
// }
// .pulse1:before, .pulse:after {
//     content: '';
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     width: 100%;
//     height: 100%;
//     background: var(--red);
//     border-radius: 50%;
//     opacity: 0.5;
//     transform: translate(-50%, -50%) scale(1);
//     animation: ripple 2.0s infinite ease-in;
// }
@keyframes ripple {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.5;
   }
   100% {
       transform: translate(-50%, -50%) scale(2);
       opacity: 0;
  }
}

.dropPins .marker {
	animation:dropPin 1.0s 1 ease-in;
}

@keyframes dropPin {
    0% {
        margin-top: calc(-100vh + 10px);
   }
   50% {
       margin-top: calc(-100vh + 100px);
  }
}

body:not(.dropPins) .bounce button:not(.is-complete) {
	animation:bounce 2.0s infinite ease-in;
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translate(-50%, -50%);}
	40% {transform: translate(-50%, -30px);}
	60% {transform: translate(-50%, -45px);}
	
}
