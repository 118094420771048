@import '/src/assets/scss/global/media-queries';
@import '/src/assets/scss/global/fonts';
@import '/src/assets/scss/global/typography';

.modal--match {
	--side-padding: 30px;
	--top-padding: 40px;

	@include respond-above(medium) {
		--side-padding: 65px;
		--top-padding: 45px;
	}
	background: var(--light-blue);
	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
	height: 100%;
	left: 0;
	overflow: auto;
	pointer-events: all;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2000;

	@include respond-above(medium) {
		height: auto;
		left: 50%;
		max-height: 80vh;
		overflow-y: auto;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 600px;
	}
}

.modal--match .modal-header {
	background-color: var(--white);
	padding-bottom: 70px;
	padding-left: var(--side-padding);
	padding-right: calc(var(--side-padding) + 20px);
	padding-top: var(--top-padding);

	@include respond-above(medium) {
		padding-bottom: 90px;
	}
}

.modal--match .modal-header__title {
	@include h1;
	margin: 0 0 10px 0;

	@include respond-above(medium) {
		margin: 0 0 0 0;
	}
}

.modal--match .modal-body {
	margin-top: -42px;
	padding-bottom: 35px;
	padding-left: var(--side-padding);
	padding-right: var(--side-padding);

	@include respond-above(medium) {
		margin-top: -70px;
		padding-bottom: 50px;
	}
}

.modal--match .modal__image-container {
	line-height: 0;
	margin-bottom: 26px;
	min-height: 163px;
	@include respond-above(large) {
		min-height: 232px;
	}
}

.modal--match .modal__image {
	width: 100%;
}

.modal--match .modal__headline {
	@include sans(400);
	font-size: 14px;
	letter-spacing: 2px;
	line-height: 1.2;
	text-transform: uppercase;

	@include respond-above(medium) {
		font-size: 18px;
		letter-spacing: 3px;
	}
}

.modal--match .modal__content {
	margin-bottom: 27px;
}

.modal--match-final {

	@include respond-above(large) {
		background: var(--white);
		max-width: 600px;
		width: 100%;
	}
}

.modal--match-final .modal__inner {
	@include respond-above(large) {
		display: flex;
	}
}

.modal--match .modal__match-signup {
	padding-bottom: 45px;

	padding-left: var(--side-padding);
	padding-right: var(--side-padding);

	@include respond-above(large) {
		background-color: var(--light-blue);
		flex: 0 0 auto;
		max-width: 400px;
		padding-bottom: 50px;
		padding-top: 107px;
	}
}

.modal--match .sign-up-form {
	border-top: 1px solid #c0d5d5;
	padding-top: 30px;

	@include respond-above(large) {
		border: none;
		padding-top: 0;
	}
}

.modal--match .sign-up-form .button {
	width: 100%;
}
