$site-width: 1224px;
$column-gutter: 30px;

@mixin container($width: $site-width, $padding: 20px) {
	margin-left: auto;
	margin-right: auto;
	max-width: calc(#{$width} + #{$padding * 2});
	padding-left: $padding;
	padding-right: $padding;

	@if $width == 100% {
		max-width: none;
	}
}

@mixin grid( $columns: 12, $gutter: $column-gutter, $autoflow: true) {
	column-gap: $gutter;
	display: grid;
	// grid-auto-columns: 1fr;
	grid-template-columns: repeat(#{$columns}, 1fr);

	@if $autoflow == true {
		grid-auto-flow: column;
	}
}

@mixin block-grid( $num, $selector, $gutter: $column-gutter ) {
	display: flex;
	flex-wrap: wrap;
	margin-left: calc(#{$gutter} / -2);
	margin-right: calc(#{$gutter} / -2);

	#{$selector} {
		margin-left: calc(#{$gutter} / 2);
		margin-right: calc(#{$gutter} / 2);
		width: calc((100% / #{$num} ) - #{$gutter});
	}
}
