@import '/src/assets/scss/global/media-queries';
@import '/src/assets/scss/global/fonts';

.player-status {
	@include sans(400);
	font-size: 14px;
	letter-spacing: 2px;
	line-height: 1.2;
	opacity: 1;
	text-align: center;
	text-transform: uppercase;
	transition: opacity 250ms ease 300ms;
	position: absolute;
	right: 5%;

	@include respond-above(large) {
		font-size: 18px;
		letter-spacing: 3px;
	}
}

.drawer--is-open .player-status {
	opacity: 0;
}
