@import '/src/assets/scss/global/media-queries';
@import '/src/assets/scss/global/fonts';

.drawer {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	transform: translateX(0);
	transition: all 0.3s;
	z-index: 5;

	@include respond-above(medium) {
		width: 60%;
	}

	@include respond-above(large) {
		width: 600px;
	}
}

.drawer[data-open="false"] {
	transform: translateX(-100%);
}

.drawer--welcome .drawer__inner {
	background-color: #E5F7F7;
	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
	height: auto;
	overflow-y: scroll;
	padding: 45px 30px;

	@include respond-above(medium) {
		height: 100%;
		padding: 80px 50px;
	}

	@include respond-above(large) {
		padding-left: 100px;
		padding-right: 100px;
		padding-top: 165px;
	}

}

.drawer--welcome .drawer__title {

	@include serif(700);
	color: #000000;
	font-size: 38px;
	line-height: 1.1;
	margin: 0 0 30px 0;

	@include respond-above(medium) {
		font-size: 60px;
	}
}

.drawer--welcome .button {
	margin-top: 40px;
}
