@import '/src/assets/scss/global/media-queries';
@import '/src/assets/scss/global/fonts';
@import '/src/assets/scss/global/typography';


.modal--successful-submission .ReactModal__Content {
	background-color: var(--light-blue);
	bottom: 0;
	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
	inset: auto 0 0 auto;
	max-width: 708px;
	padding: 44px;
	position: absolute;
	right: 0;
	width: 100%;
}

.modal--successful-submission .btn-close {
	background: none;
	border: none;
	height: 28px;
	padding: 0;
	position: absolute;
	right: 25px;
	top: 33px;
	width: 28px;
}

.modal--successful-submission .btn-close svg:hover > path {
	fill: var(--red);
}

.modal--successful-submission .modal__title {
	@include h1;
	margin: 0;
	padding-right: 15px;
}

.modal--successful-submission .modal__message {
	margin-top: 30px;
}

.modal--successful-submission .ReactModal__Overlay {
	background-color: transparent !important;
}
