@import '/src/assets/scss/global/fonts';

.sign-up-form__form {
	margin-top: 20px;
}

.sign-up-form .form-item + .form-item {
	margin-top: 8px;
}

.sign-up-form .form-item .label {
	@include sans(700);
	color: var(--grey);
	display: block;
	font-size: 8px;
	letter-spacing: 1px;
	line-height: 1;
	margin-bottom: 4px;
	text-transform: uppercase;
}

.sign-up-form input[type="text"],
.sign-up-form input[type="email"] {
	@include serif(400);
	border: 1px solid #B7D8D7;
	font-size: 18px;
	height: 48px;
	line-height: 1;
	padding: 0 12px;
	width: 100%;
}

.sign-up-form input[type="checkbox"] + .label {
	display: inline-block;
	font-size: 14px;
	margin-left: 8px;
}

.field-error {
	@include sans(700);
	color: var(--red);
	font-size: 12px;
	letter-spacing: 0.2px
}

.sign-up-form .button {
	margin-top: 20px;
}

.sign-up-form__additional-info {
	border-top: 1px solid #c0d5d5;
	margin-top: 40px;
}

.cotopaxi-link {
	color: var(--red);
	text-decoration: underline;
}


a.cotopaxi-link:hover {
	color: #e51232;
}
