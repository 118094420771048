@import '/src/assets/scss/global/index';

.section--complete {
	background-color: var(--light-blue);
	height: 100%;
	position: relative;
}

.section--complete .bg-image__container {
	line-height: 0;
	@include respond-above(medium) {
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

.section--complete .bg-image__image {
	width: 100%;
	@include respond-above(medium) {
		height: 100%;
		object-fit: cover;
	}
}

.completed-message {
	background-color: var(--light-blue);
	padding: 45px 30px;

	@include respond-above(medium) {
		max-width: 604px;
		padding: 70px 94px;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	}
}

.completed-message__title {
	@include h1;
	margin: 0 0 30px 0;
}

.completed-message__links {
	margin-top: 40px;
}

.completed-message__links li + li {
	margin-top: 20px;
}

.completed-message__links a {
	min-width: 236px;
	@include respond-above(medium) {
		min-width: 312px;
	}
}

.completed-message__social-links {
	margin-top: 30px;
}
