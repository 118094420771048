:root {
	--red: #CF102D;
	--bright-red: #F11035;

	--white: #ffffff;

	--light-blue: #E5F7F7;

	--grey: #4D525A;

	--black: #000;
}
